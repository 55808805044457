import React from "react";
import geoService from "../assets/images/geoServices.svg";
import smartVideo from "../assets/images/smartVideo.svg";
import realTimeInfo from "../assets/images/realTimeInfo.svg";
import smartAutomatization from "../assets/images/smartAutomatization.svg";
import "../assets/css/security.css";

export const Security = (data) => {
  const url = window.location.pathname;
  const phone = data.data.phoneNumber
  return (
    <div className="security">
      <h2>¿Conocés la nueva plataforma ADT Interactive Security?</h2>
      <div className="security-images">
        <img src={geoService} alt="" />
        <img src={smartVideo} alt="" />
        <img src={realTimeInfo} alt="" />
        <img src={smartAutomatization} alt="" />
      </div>
      {url === "/seguridad" ? (
        <a href="tel:08003451886" style={{ textDecoration: "none" }}>
          <button value="Obtener más información">Obtener más información</button>
        </a>
      ):(
        <a href={`tel:${phone}`} style={{ textDecoration: "none" }}>
          <button value="Obtener más información">Obtener más información</button>
        </a>
      )}
    </div>
  );
};
